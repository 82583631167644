import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="Data Protection and Storage">
    <section>
      Quantic provides a consultative, best practice-based approach to solving business needs and mitigating risk. We have a long, successful history of
      architecting data protection strategies that focus on backup, business continuity and disaster recovery. Benefit from our architects who have more than 15
      years of data protection experience and real-world expertise with industry leading solutions.
      <br/>
      <br/>
      Our tools-assisted analysis and engineering-led solutions have delivered measurable results to our customers:
      <br/>
      <br/>

      <ul>
        <li>Reduced operating costs.</li>
        <li>Meet compliance and regulatory requirements.</li>
        <li>Consolidation of multiple backup environments.</li>
        <li>Emphasis on achieving RTO and RPO.</li>
        <li>Effective management of data growth.</li>
        <li>Dependable backup strategies.</li>
        <li>Cloud integration for archival data.</li>
        <li>Data center migration.</li>
      </ul>
    </section>
    <br/>
    <section>
      Quantic has invested significant resources into understanding storage industry. Quantic has the expertise to architect, implement, manage, and support
      the right storage solution for your workload environment and align it with business objectives to make the most impact.
      <br/>
      <br/>

      With the right approach, architecture, and deployment, storage can provide competitive advantages and reduce the capital and operational costs of your
      data centers.
      <br/>
      <br/>
      <strong>Flash Storage:</strong> In today`s fast pace, business is all about speed and agility and Flash is the high-octane storage media behind it. Our
      commitment to future innovation continues to increase the value of Flash for your applications and your business.
      <br/>
      <br/>
      <strong>Flash array:</strong> It provides vastly superior performance; fewer spikes in latency, better disaster recovery, support of real-time analytics,
      much faster data transfer rates, and the ability to free IT staff to focus on other tasks. All-Flash Arrays provide the foundation for next-generation
      business applications, and the All-Flash data center.
      <br/>
      <br/>
      <strong>Object Storage:</strong> An object is a piece of data paired with any associated metadata that provides context about the bytes contained within
      the object. Two things the data and metadata together—make an object. The Data stored in objects is uncompressed and unencrypted, and the objects
      themselves are arranged in object stores
    </section>
    <br/>
  </Layout>
);

export default Page;
